import { robotConstants, userConstants } from "./constants";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";

export const userlogin = (data) => {
  return {
    type: "USER_LOGIN",
    payload: data,
  };
};
export const FleetIdAction = (data) => {
  return {
    type: "FETCH_FLEET_ID",
    payload: data,
  };
};
export const robotDataAction = (data) => {
  return {
    type: "FETCH_ROBOT_DATA",
    payload: data,
  };
};
export const robotmsgAction = (data) => {
  return {
    type: "FETCH_ROBOTMSG_DATA",
    payload: data,
  };
};
export const RobotIdAction = (data) => {
  return {
    type: "GET_ROBOT_ID",
    payload: data,
  };
};
export const robotlistdataAction = (data) => {
  return {
    type: "FETCH_ROBOT_LIST_DATA",
    payload: data,
  };
};
export const sshEndPointAction = (data) => {
  return {
    type: "FETCH_SSH_ENDPOINT",
    payload: data,
  };
};
export const fleetlatitudeaction = (data) => {
  return {
    type: "FETCH__FLEET_LATITUDE",
    payload: data,
  };
};
export const fleetlogitudeaction = (data) => {
  return {
    type: "FETCH__FLEET_LONGITUDE",
    payload: data,
  };
};

// Login
export const loginUser = (email, password) => async (dispatch) => {
  try {
    dispatch({
      type: userConstants.LOGIN_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.post(
      `${process.env.REACT_APP_BASE_URL}login-user`,
      { emailId: email, password: password },
      config
    );

    if (data.status === "B002") {
      throw new Error(data.message);
    }

    sessionStorage.setItem("useremail", email);
    localStorage.setItem("useremail", email);
    // document.cookie = data.data.token;
    // sessionStorage.setItem("password", password);
    sessionStorage.setItem("Isloggedin", "Logintrue");
    sessionStorage.setItem("token", data.data.token);
    sessionStorage.setItem("role", JSON.stringify(data.data.role));
    localStorage.setItem("firstName", data.data.firstName);
    localStorage.setItem("lastName", data.data.lastName);
    // sessionStorage.setItem("uniqueId", uuidv4());
    if (!localStorage.getItem("uniqueId")) {
      localStorage.setItem("uniqueId", uuidv4());
    }

    dispatch({
      type: userConstants.LOGIN_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    dispatch({
      type: userConstants.LOGIN_FAIL,
      payload: error.message,
    });
  }
};
//Logout user
export const logoutUser = () => (dispatch) => {
  try {
    // const config = {
    //   withCredentials: true,
    // };
    // await axios.get(`${server}/logout`, config);
    dispatch({ type: userConstants.LOGOUT_SUCCESS });
  } catch (error) {
    dispatch({
      type: userConstants.LOGOUT_FAIL,
      payload: error,
    });
  }
};

// GET all robots details
export const storeRobotDetails = (fleetId) => async (dispatch) => {
  try {
    dispatch({
      type: robotConstants.ROBOT_DETAILS_REQUEST,
    });

    const token = sessionStorage.getItem("token");

    let { data } = await axios.post(
      `${process.env.REACT_APP_BASE_URL}robot/v1/getRobotStatusByFleetId`,
      {
        fleetId: fleetId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    let robotDetail = {};

    data.data.forEach((robot) => {
      robotDetail[robot.robotId] = robot;
    });

    if (Object.keys(robotDetail).length !== 0) {
      dispatch({
        type: robotConstants.ROBOT_DETAILS_SUCCESS,
        payload: robotDetail,
      });
    } else {
      throw new Error("No robot found!");
    }
  } catch (error) {
    dispatch({
      type: robotConstants.ROBOT_DETAILS_FAIL,
      payload: error.message,
    });
  }
};

// GET all robots details
export const getRobotDetail = (robotId) => async (dispatch) => {
  try {
    dispatch({
      type: robotConstants.ROBOT_DETAILS_REQUEST,
    });

    const token = sessionStorage.getItem("token");

    let robotDetail = {};

    const { data: robotStatusData } = await axios
      .post(
        `${process.env.REACT_APP_BASE_URL}robot/v1/getRobotStatus`,
        {
          robotId: robotId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .catch((err) => {
        robotDetail[robotId] = {};
      });

    const { data: robotCommandData } = await axios
      .post(
        `${process.env.REACT_APP_BASE_URL}robot/v1/getRobotCommandListByRobotId`,
        {
          robotId: robotId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .catch((err) => {
        robotDetail[robotId].robotCommandList = [];
      });
    const { data: robotPathData } = await axios
      .post(
        `${process.env.REACT_APP_BASE_URL}robot/v2/getRobotPath`,
        {
          robotId: robotId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .catch((err) => {
        robotDetail[robotId].path = [];
      });

    robotDetail[robotId] = robotStatusData.data.robotStatus;
    robotDetail[robotId].robotCommandList = robotCommandData.robotCommandList;
    robotDetail[robotId].path = robotPathData.path ? robotPathData.path : [];

    dispatch({
      type: robotConstants.ROBOT_DETAILS_SUCCESS,
      payload: robotDetail,
    });
  } catch (error) {
    dispatch({
      type: robotConstants.ROBOT_DETAILS_FAIL,
      payload: error,
    });
  }
};

// GET all robots command
// export const getRobotAction = (robotId) => async (dispatch) => {
//   try {
//     dispatch({
//       type: robotConstants.ROBOT_ACTION_REQUEST,
//     });

//     const token = sessionStorage.getItem("token");

//     const { data } = await axios.post(
//       `${process.env.REACT_APP_BASE_URL}robot/v1/getRobotCommandListByRobotId`,
//       {
//         robotId: robotId,
//       },
//       {
//         headers: {
//           Authorization: `Bearer ${token}`,
//           "Content-Type": "application/json",
//         },
//       }
//     );

//     let robotDetail = {};
//     console.log("robotCommandList", data);

//     robotDetail[robotId] = { robotCommandList: data.robotCommandList };

//     dispatch({
//       type: robotConstants.ROBOT_ACTION_SUCCESS,
//       payload: robotDetail,
//     });
//   } catch (error) {
//     dispatch({
//       type: robotConstants.ROBOT_ACTION_FAIL,
//       payload: error,
//     });
//   }
// };

// GET robot path
// export const getRobotPath = (robotId) => async (dispatch) => {
//   try {
//     dispatch({
//       type: robotConstants.ROBOT_PATH_REQUEST,
//     });

//     const token = sessionStorage.getItem("token");

//     const { data } = await axios.post(
//       `${process.env.REACT_APP_BASE_URL}robot/v2/getRobotPath`,
//       {
//         robotId: robotId,
//       },
//       {
//         headers: {
//           Authorization: `Bearer ${token}`,
//           "Content-Type": "application/json",
//         },
//       }
//     );

//     let robotDetail = {};

//     robotDetail[robotId] = { path: data.path };

//     dispatch({
//       type: robotConstants.ROBOT_PATH_SUCCESS,
//       payload: robotDetail,
//     });
//   } catch (error) {
//     dispatch({
//       type: robotConstants.ROBOT_PATH_FAIL,
//       payload: error,
//     });
//   }
// };

// ORDER STATUS
export const getOrderStatus = (orderId) => async (dispatch) => {
  try {
    dispatch({
      type: robotConstants.ORDER_DETAILS_REQUEST,
    });

    const token = sessionStorage.getItem("token");

    const { data } = await axios.post(
      `${process.env.REACT_APP_BASE_URL}order/status`,
      {
        orderId: orderId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    dispatch({
      type: robotConstants.ORDER_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: robotConstants.ORDER_DETAILS_FAIL,
      payload: error,
    });
  }
};

export const getRobotDirection =
  (directionsService, origin, destination) => async (dispatch) => {
    try {
      dispatch({
        type: robotConstants.ROBOT_DIRECTIONS_REQUEST,
      });

      const data = await directionsService.route({
        origin: origin,
        destination: destination,
        travelMode: window.google.maps.TravelMode.WALKING,
      });

      if (data.status === window.google.maps.DirectionsStatus.OK) {
        dispatch({
          type: robotConstants.ROBOT_DIRECTIONS_SUCCESS,
          payload: data,
        });
      } else {
        throw new Error(`error fetching directions ${data}`);
      }
    } catch (error) {
      dispatch({
        type: robotConstants.ROBOT_DIRECTIONS_FAIL,
        payload: error,
      });
    }
  };

export const getRobotDirectionbyMapbox =
  (origin, destination) => async (dispatch) => {
    try {
      dispatch({
        type: robotConstants.ROBOT_DIRECTIONS_REQUEST,
      });

      const data = await axios.get(
        `https://api.mapbox.com/directions/v5/mapbox/walking/${origin.lng},${origin.lat};${destination.lng},${destination.lat}?geometries=geojson&access_token=${process.env.REACT_APP_MAPBOXTOKEN}`
      );
      dispatch({
        type: robotConstants.ROBOT_DIRECTIONS_SUCCESS,
        payload: data.data,
      });
    } catch (error) {
      dispatch({
        type: robotConstants.ROBOT_DIRECTIONS_FAIL,
        payload: error,
      });
    }
  };

// Config page settings
export const sendCommandData = (arrayState) => (dispatch) => {
  try {
    dispatch({
      type: robotConstants.ROBOT_SEND_COMMAND_DATA_REQUEST,
    });

    if (arrayState) {
      dispatch({
        type: robotConstants.ROBOT_SEND_COMMAND_DATA_SUCCESS,
        payload: [arrayState],
      });
    }
  } catch (error) {
    dispatch({
      type: robotConstants.ROBOT_SEND_COMMAND_DATA_ERROR,
      payload: error,
    });
  }
};

//Update Modified Key
export const updateModifiedKey = (key) => async (dispatch) => {
  dispatch({
    type: robotConstants.UPDATE_MODIFIED_KEY,
    payload: key,
  });
};

//Update Modified Axis
export const updateModifiedAxis = (axis) => async (dispatch) => {
  dispatch({
    type: robotConstants.UPDATE_MODIFIED_AXIS,
    payload: axis,
  });
};

export const destroySession = () => (dispatch) => {
  try {
    dispatch({
      type: robotConstants.DESTROY_SESSION,
    });
    dispatch({
      type: userConstants.DESTROY_SESSION,
    });
  } catch (error) {
    // dispatch({
    //   type: robotConstants.ROBOT_DETAILS_FAIL,
    //   payload: error,
    // });
  }
};

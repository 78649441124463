import { memo, useState } from "react";
import {
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Box,
  TextField,
} from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import "./Dropdown.css";

const theme = createTheme({
  status: {
    danger: "#e53e3e",
  },
  palette: {
    primary: {
      main: "#ffffff",
      darker: "#053e85",
    },
    neutral: {
      main: "#64748B",
      contrastText: "#fff",
    },
  },
});

const Dropdown = (props) => {
  //   const dispatch = useDispatch();

  let inputText = props.inputText;
  let controllerTypes = props.controllerTypes;
  let positions = props.positions;

  const [state1, setState1] = useState({
    [inputText]: "",
  });

  const handleChange = (event) => {
    setState1({ ...state1, [inputText]: event.target.value });
    props.getControllerType(event.target.value);
    // dispatch(sendCommandData(inputText, event.target.value));
  };

  return (
    <>
      <Box sx={{ minWidth: 120, width: "100%" }}>
        <ThemeProvider theme={theme}>
          <FormControl fullWidth>
            {props.dropdown === "false" ? null : (
              <InputLabel
                className="input-mui"
                color="primary"
                id="demo-simple-select-label"
              >
                {inputText}
              </InputLabel>
            )}
            {props.dropdown === "false" ? (
              <TextField
                id="outlined-basic"
                defaultValue={0}
                label={inputText}
                sx={{ color: "white", background: "#2b2a44" }}
              />
            ) : (
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={state1[inputText]}
                label={inputText}
                onChange={handleChange}
                sx={{ color: "white", background: "#2b2a44" }}
              >
                {controllerTypes &&
                  controllerTypes.map((values, index) => {
                    return (
                      <MenuItem key={index} value={values}>
                        {values}
                      </MenuItem>
                    );
                  })}

                {inputText === "Positions" &&
                  positions &&
                  positions.map((values, index) => {
                    return (
                      <MenuItem key={index} value={values}>
                        {values}
                      </MenuItem>
                    );
                  })}
              </Select>
            )}
          </FormControl>
        </ThemeProvider>
      </Box>
    </>
  );
};

export default memo(Dropdown);

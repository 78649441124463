import { Backdrop, Box, Fade, Modal } from "@mui/material";

const openDuplicateModalStyling = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "max-content",
  bgcolor: "transparent !important",
  outline: "none",
  p: 4,
};

const DuplicateTabModal = ({
  openDuplicateTabModal,
  setOpenDuplicateTabModal,
  robotId,
}) => {
  const handleDuplicateTabModal = (event, reason) => {
    setOpenDuplicateTabModal(false);
  };

  return (
    <Modal
      open={openDuplicateTabModal}
      backdrop="static"
      onClose={handleDuplicateTabModal}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          sx: {
            backgroundColor: "rgb(0,0,0,0.84)",
          },
          timeout: 500,
        },
      }}
    >
      <Fade in={openDuplicateTabModal}>
        <Box sx={openDuplicateModalStyling}>
          <div className="flex flex-col justify-center w-full gap-3 text-center item-center">
            <div className="text-center item-center justify-center w-full flex flex-col text-[#BEC4DE] text-[26px] font-bold">
              <span>You are already operating </span>
              <span>Ottobot {robotId} on this system.</span>
            </div>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
};

export default DuplicateTabModal;

export const robotConstants = {
  ROBOT_DETAILS_REQUEST: "ROBOT_DETAILS_REQUEST",
  ROBOT_DETAILS_SUCCESS: "ROBOT_DETAILS_SUCCESS",
  ROBOT_DETAILS_FAIL: "ROBOT_DETAILS_FAIL",

  ROBOT_STATUS_REQUEST: "ROBOT_STATUS_REQUEST",
  ROBOT_STATUS_SUCCESS: "ROBOT_STATUS_SUCCESS",
  ROBOT_STATUS_FAIL: "ROBOT_STATUS_FAIL",

  ROBOT_ACTION_REQUEST: "ROBOT_ACTION_REQUEST",
  ROBOT_ACTION_SUCCESS: "ROBOT_ACTION_SUCCESS",
  ROBOT_ACTION_FAIL: "ROBOT_ACTION_FAIL",

  ROBOT_PATH_REQUEST: "ROBOT_PATH_REQUEST",
  ROBOT_PATH_SUCCESS: "ROBOT_PATH_SUCCESS",
  ROBOT_PATH_FAIL: "ROBOT_PATH_FAIL",

  ORDER_DETAILS_REQUEST: "ORDER_DETAILS_REQUEST",
  ORDER_DETAILS_SUCCESS: "ORDER_DETAILS_SUCCESS",
  ORDER_DETAILS_FAIL: "ORDER_DETAILS_FAIL",

  ROBOT_DIRECTIONS_REQUEST: "ROBOT_DIRECTIONS_REQUEST",
  ROBOT_DIRECTIONS_SUCCESS: "ROBOT_DIRECTIONS_SUCCESS",
  ROBOT_DIRECTIONS_FAIL: "ROBOT_DIRECTIONS_FAIL",

  ROBOT_SEND_COMMAND_DATA_REQUEST: "ROBOT_SEND_COMMAND_DATA_REQUEST",
  ROBOT_SEND_COMMAND_DATA_SUCCESS: "ROBOT_SEND_COMMAND_DATA_SUCCESS",
  ROBOT_SEND_COMMAND_DATA_ERROR: "ROBOT_SEND_COMMAND_DATA_ERROR",

  UPDATE_MODIFIED_KEY: "UPDATE_MODIFIED_KEY",
  UPDATE_MODIFIED_AXIS: "UPDATE_MODIFIED_AXIS",

  DESTROY_SESSION: "DESTROY_SESSION",

  CLEAR_ERRORS: "CLEAR_ERRORS",
};

export const userConstants = {
  LOGIN_REQUEST: "LOGIN_REQUEST",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_SUCCESS_MESSAGE_RESET: "LOGIN_SUCCESS_MESSAGE_RESET",
  LOGIN_FAIL: "LOGIN_FAIL",

  LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
  LOGOUT_FAIL: "LOGOUT_FAIL",

  DESTROY_SESSION: "DESTROY_SESSION",

  CLEAR_ERRORS: "CLEAR_ERRORS",
};

import { Backdrop, Box, Fade, Modal } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

const openAcceptTransferModalStyling = {
  position: "absolute",
  width: "100%",
  height: "100%",
  bgcolor: "transparent !important",
  outline: "none",
  p: 1,
};

const AcceptTransferModal = ({
  openAcceptTransferModal,
  setOpenAcceptTransferModal,
  robotId,
  controllerData,
  signallingChannel,
  peerId,
  target,
  setUnlockChecked,
  unlockController,
  lock,
  accessTimer,
  setAccessTimer,
}) => {
  const { user } = useSelector((state) => state.userAuthReducer);

  const [acceptAccessClicked, setAcceptAccessClicked] = useState(false);

  const timerId = useRef(null);

  const clearTimer = () => {
    window.clearInterval(timerId.current);
  };

  const firstName = user?.firstName;
  const lastName = user?.lastName;
  const emailId = localStorage.getItem("useremail");

  const handleOpenAcceptTransferModal = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    setOpenAcceptTransferModal(false);
    setAccessTimer(-1);
  };

  const handleAcceptAccess = useCallback(() => {
    Object.keys(signallingChannel).length > 0 &&
      signallingChannel?.acceptAccess({
        peerId: controllerData.requesteePeerId,
        // target: robotId,
        target: target,
      });
    signallingChannel?.requestCom({
      peerId: peerId,
      target: target,
    });
    setOpenAcceptTransferModal(false);
    setUnlockChecked(false);
    unlockController(false);
    lock(false);
  }, [
    controllerData.requesteePeerId,
    lock,
    peerId,
    setOpenAcceptTransferModal,
    setUnlockChecked,
    signallingChannel,
    target,
    unlockController,
  ]);

  useEffect(() => {
    if (accessTimer && accessTimer > 0) {
      timerId.current = window.setInterval(() => {
        setAccessTimer((time) => time - 1);
      }, 1000);
      return () => clearTimer();
    }
  }, [accessTimer, setAccessTimer]);

  useEffect(() => {
    if (accessTimer === 0) {
      clearTimer();
      setOpenAcceptTransferModal(false);
      handleAcceptAccess();
      setAcceptAccessClicked(false);
      setAccessTimer(-1);
    }
  }, [
    accessTimer,
    setOpenAcceptTransferModal,
    handleAcceptAccess,
    acceptAccessClicked,
    setAccessTimer,
  ]);

  return (
    <Modal
      open={openAcceptTransferModal}
      backdrop="static"
      onClose={handleOpenAcceptTransferModal}
      sx={{
        right: 10,
        bottom: 0,
        top: "auto",
        left: "auto",
        width: "350px",
        height: "200px",
      }}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          sx: {
            position: "absolute",
            backgroundColor: "rgb(0,0,0,0.84)",
            borderRadius: "10px",
            top: "auto",
            left: "auto",
            width: "100%",
            height: "100%",
          },
          timeout: 500,
        },
      }}
    >
      <Fade in={openAcceptTransferModal}>
        <Box sx={openAcceptTransferModalStyling}>
          <div className="absolute flex flex-col justify-center gap-2.5 w-full h-full text-center item-center !p-3">
            <div className="flex flex-col items-center gap-1">
              <span className="w-10 h-10 flex items-center justify-center rounded-full bg-[#BEC4DE] text-[#1C1E2B] text-xl uppercase font-bold border-white border-2">
                {/* {firstName && firstName[0]} */}

                {firstName || lastName ? (
                  <>
                    {firstName && firstName[0]}
                    {lastName && lastName[0]}
                  </>
                ) : (
                  <>
                    {emailId &&
                      emailId !== "undefined" &&
                      emailId !== "null" &&
                      emailId[0]}
                  </>
                )}

                {/* {controllerData?.assignedPeerId?.split("_")?.slice(0, -1)[0][0]}
                {controllerData?.assignedPeerId?.split("_")?.slice(0, -1)[1][0]} */}
              </span>
              <span className="text-sm font-normal text-white capitalize">
                {/* {controllerData?.assignedPeerId?.split("_")?.slice(0, -1)[0]}{" "}
                {controllerData?.assignedPeerId?.split("_")?.slice(0, -1)[1]} */}
                {/* {firstName + " " + lastName} */}
                {firstName || lastName ? (
                  <>{firstName + " " + lastName}</>
                ) : (
                  <>
                    {emailId &&
                      emailId !== "undefined" &&
                      emailId !== "null" &&
                      emailId}
                  </>
                )}
              </span>
            </div>
            <div className="text-center item-center justify-center w-full flex flex-col text-[#BEC4DE] text-base font-bold">
              <span>
                {controllerData?.requesteePeerId?.split("_")?.slice(0, -1)[0]}{" "}
                {controllerData?.requesteePeerId?.split("_")?.slice(0, -1)[1]}{" "}
                is requesting to
              </span>
              <span>
                take control of{" "}
                <span className="text-white">Robot "{target}"</span>
              </span>
              {/* <span>
                from{" "}
                {controllerData?.assignedPeerId
                  ?.split("_")
                  ?.slice(0, -1)
                  ?.join(" ")}
                ?
              </span> */}
            </div>
            <div className="flex items-center justify-center w-full h-auto gap-2">
              <span
                onClick={() => {
                  setAccessTimer(0);
                  // handleAcceptAccess();
                  // setAcceptAccessClicked(true);
                }}
                className="h-full flex justify-center w-[25%] items-center text-[#1C1E2B] bg-[#BEC4DE] rounded-md text-base font-bold cursor-pointer"
              >
                Yes
              </span>
              <span
                onClick={handleOpenAcceptTransferModal}
                className="h-full flex justify-center w-[25%] items-center text-[#BEC4DE] bg-transparent border border-white rounded-md text-base font-bold cursor-pointer"
              >
                No
              </span>
            </div>
          </div>
          <span className="absolute top-0 right-0 text-xl text-[#BEC4DE] font-bold p-2">
            {accessTimer !== -1 && accessTimer}
          </span>
        </Box>
      </Fade>
    </Modal>
  );
};

export default AcceptTransferModal;

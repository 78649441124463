import { memo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SlPower } from "react-icons/sl";
import { showSuccessToast } from "../../toast/toastType";
import { useDispatch } from "react-redux";
import { destroySession } from "../../../redux/actions";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
// import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 430,
  bgcolor: "#282F42",
  boxShadow: 24,
  p: 4,
  borderRadius: "10px",
};

function LogoutModal({ header, dashboard, signallingChannel }) {
  const [logoutPopup, setLogoutPopup] = useState(false);

  const handleClose = (reason) => {
    if (reason && reason === "backdropClick") return;
    setLogoutPopup(false);
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handlelogoutbtn = () => {
    // sessionStorage.setItem("useremail", null);
    // localStorage.removeItem("firstName");
    // localStorage.removeItem("lastName");
    // localStorage.removeItem("uniqueId");
    // localStorage.removeItem("PEER_ID");
    // sessionStorage.setItem("password", null);
    // sessionStorage.setItem("token", null);
    // sessionStorage.setItem("fleetID", null);
    // sessionStorage.setItem("Isloggedin", "LoginFalse");
    // sessionStorage.setItem("mapid", null);
    // sessionStorage.setItem("Distance", null);
    // sessionStorage.setItem("robotId", null);
    // sessionStorage.setItem("kmlurl", null);
    // sessionStorage.setItem("role", null);
    sessionStorage.clear();
    localStorage.clear();
    navigate("/");
    dispatch(destroySession());
    showSuccessToast("Logged out successfully!");
  };
  return (
    <>
      {header ? (
        <button
          className="text-3xl cancel-order"
          onClick={() => setLogoutPopup(true)}
        >
          <SlPower className="text-[#00B7D4]" />
        </button>
      ) : (
        <button
          className="cancel-order flex justify-start !text-white h-full items-center p-0 ml-4 !capitalize !font-poppins !text-xs !w-full"
          onClick={() => setLogoutPopup(true)}
        >
          Logout
        </button>
      )}

      <Modal open={logoutPopup} backdrop="static">
        <Box sx={style}>
          <div className="Cancel-order-Modal-Mainwrapper">
            <p> Are you sure want to logout ?</p>
            <div className="Modal-button-Main-wrapper">
              <span
                className="!text-[#2b2a44] !bg-white Modal-button-Main-btn yes-red-active !cursor-pointer capitalize"
                onClick={() => {
                  handleClose();
                  handlelogoutbtn();
                  signallingChannel &&
                    Object.keys(signallingChannel).length > 0 &&
                    signallingChannel?.disconnect();
                }}
              >
                <span>Yes</span>
              </span>
              <button
                className="Modal-button-Main-btn capitalize !cursor-pointer"
                onClick={handleClose}
              >
                {" "}
                <span>No</span>
              </button>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
}
export default memo(LogoutModal);

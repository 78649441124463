import { memo, useEffect, useState } from "react";
import { Box, Modal } from "@mui/material";
import { showErrorToast, showSuccessToast } from "../toast/toastType";
import {
  handleResumeAutoInterrupt,
  handleTeleopAckInterrupt,
  handleTeleopComplete,
} from "../../apis";
import ringer from "../../assets/audios/alert.wav";

function TeleopCompleted(props) {
  const [completedDisabled, setCompletedDisabled] = useState(true);
  const [, setCompletedDisabledInner] = useState(false);
  const [lastCheck, setLastCheck] = useState(true);
  const [open, setOpen] = useState(false);
  const [openResumeAutoConfirmationModal, setOpenResumeAutoConfirmationModal] =
    useState(false);

  const audio = new Audio(ringer);
  const token = sessionStorage.getItem("token");
  const robotId = props?.robotId;

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 825,
    bgcolor: "#282F42",
    p: 4,
    borderRadius: "11px",
    boxShadow: "0px 0px 20px rgb(0 0 0 / 40%)",
  };

  const pauseBeepSound = () => {
    function pause() {
      audio.pause();
      audio.loop = false;
      audio.muted = true;
      audio.src = "";
    }
    pause();
  };

  const handleTeleopAcknowledge = (robotId) => {
    handleTeleopAckInterrupt(robotId)
      .then((res) => {
        showSuccessToast("TELEOP Acknowledged successfully!");
        setCompletedDisabled(false);
        setLastCheck(true);
        pauseBeepSound();
      })
      .catch((err) => {
        showErrorToast("TELEOP Acknowledge failed!");
      });
  };

  const handleResumeAuto = () => {
    setOpenResumeAutoConfirmationModal(true);
  };

  const handleResumeAutoConfirmation = () => {
    handleResumeAutoInterrupt(robotId, token)
      .then((res) => {
        console.log("Resume Auto Interrupt command response", res.data);
        showSuccessToast("RESUME AUTO command sent successfully!");
        // setCompletedDisabled(false);
        // setLastCheck(true);
        // pauseBeepSound();
      })
      .catch((err) => {
        showErrorToast("RESUME AUTO command failed to send!");
      });
  };

  const handleTeleopCompleted = (robotId) => {
    handleTeleopComplete(robotId)
      .then((res) => {
        showSuccessToast("TELEOP completed successfully!");
        setCompletedDisabledInner(true);
      })
      .catch((err) => {
        showErrorToast("TELEOP completed failed!");
      });
  };

  const handleClose = (reason) => {
    if (reason && reason === "backdropClick") return;
    setOpen(false);
  };

  useEffect(() => {
    if (
      props?.robotData?.robotRunningState !== "TELE_OP_MOVING" &&
      props?.robotData?.robotRunningState !== "TELE_OP" &&
      props?.robotData?.robotRunningState !== "TELE_OP_OBSTACLE" &&
      props?.robotData?.teleop === "YES"
    ) {
      setCompletedDisabled(true);
      setLastCheck(false);
    } else {
      setLastCheck(false);
      setCompletedDisabled(false);
    }
  }, [props]);

  return (
    <>
      <Modal backdrop="static" open={open}>
        <Box sx={style}>
          <p className="Modal-wrapper-p">
            {props.robotId} Robot Required Teleop Ack
          </p>
          <div className="Modal-buttons-wrapper d-flex-center-center">
            <button
              className="Modal-btn Modal-btn-red"
              onClick={() => {
                handleTeleopAcknowledge(props?.robotId);
                handleClose();
              }}
            >
              {" "}
              Teleop Ack
            </button>
            <button className="Modal-btn" onClick={handleClose}>
              {" "}
              No
            </button>
          </div>
        </Box>
      </Modal>
      <Modal backdrop="static" open={openResumeAutoConfirmationModal}>
        <Box sx={style}>
          <p className="Modal-wrapper-p">
            Are you sure you want to continue {robotId} in AUTO?
          </p>
          <div className="Modal-buttons-wrapper d-flex-center-center">
            <button
              className="Modal-btn Modal-btn-red"
              onClick={() => {
                handleResumeAutoConfirmation();
                setOpenResumeAutoConfirmationModal(false);
              }}
            >
              {" "}
              Yes
            </button>
            <button
              className="Modal-button-Main-btn"
              onClick={() => setOpenResumeAutoConfirmationModal(false)}
            >
              {" "}
              No
            </button>
          </div>
        </Box>
      </Modal>
      {!lastCheck && (
        <>
          {completedDisabled && (
            <>
              {!!Number(process.env.REACT_APP_RESUME_AUTO) && (
                <div
                  className={"robot__resume__auto"}
                  onClick={() => handleResumeAuto(props?.robotId)}
                >
                  RESUME AUTO
                </div>
              )}
              <div
                className={"robot__teleop__ack"}
                onClick={() => handleTeleopAcknowledge(props?.robotId)}
              >
                TELEOP ACK{" "}
              </div>
              <audio preload="auto" loop autoPlay={true} id="playaudio">
                <source
                  src="/static/media/alert.aa9b8554cbeaf7968e81.wav"
                  type="audio/wav"
                ></source>
              </audio>
            </>
          )}
          {!completedDisabled &&
            (props?.robotData?.robotRunningState === "TELE_OP_MOVING" ||
              props?.robotData?.robotRunningState === "TELE_OP" ||
              props?.robotData?.robotRunningState === "TELE_OP_OBSTACLE") && (
              <>
                <button
                  className={
                    !completedDisabled
                      ? "Dashboard-page-teleop-button Teleop_completed_btn"
                      : "Dashboard-page-teleop-button-disabled"
                  }
                  onClick={() => handleTeleopCompleted(robotId)}
                >
                  {" "}
                  Teleop Completed
                </button>
              </>
            )}
        </>
      )}
    </>
  );
}

export default memo(TeleopCompleted);

import { Box, Modal } from "@mui/material";
import { handleClearCommandsInterrupt } from "../../../apis";
import { showErrorToast, showSuccessToast } from "../../toast/toastType";

const ClearCommandsModal = ({
  openClearCommandsModal,
  setOpenClearCommandsModal,
  robotId,
}) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 825,
    bgcolor: "#282F42",
    p: 4,
    borderRadius: "11px",
    boxShadow: "0px 0px 20px rgb(0 0 0 / 40%)",
  };

  const token = sessionStorage.getItem("token");

  const handleClearCommandsConfirmation = () => {
    handleClearCommandsInterrupt(robotId, token)
      .then((res) => {
        console.log("Clear Commands Interrupt command response", res.data);
        showSuccessToast("CLEAR_COMMANDS command sent successfully!");
      })
      .catch((err) => {
        showErrorToast("CLEAR_COMMANDS command failed to send!");
      });
  };

  return (
    <Modal backdrop="static" open={openClearCommandsModal}>
      <Box sx={style}>
        <p className="Modal-wrapper-p">
          Are you sure you want to clear commands of {robotId}?
        </p>
        <div className="Modal-buttons-wrapper d-flex-center-center">
          <button
            className="Modal-btn Modal-btn-red"
            onClick={() => {
              handleClearCommandsConfirmation();
              setOpenClearCommandsModal(false);
            }}
          >
            {" "}
            Yes
          </button>
          <button
            className="Modal-button-Main-btn"
            onClick={() => setOpenClearCommandsModal(false)}
          >
            {" "}
            No
          </button>
        </div>
      </Box>
    </Modal>
  );
};

export default ClearCommandsModal;

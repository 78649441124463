import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // '&:nth-of-type(odd)': {
  //     backgroundColor: theme.palette.action.hover,
  // },
  // // hide last border
  // '&:last-child td, &:last-child th': {
  //     border: 0,
  // },
}));

export default function StreamStatsPopup({ streamStats, showStreamStats }) {
  const filteredStreamStats = streamStats && [
    {
      name: "Current RTT",
      value: Math.ceil(streamStats?.currentRoundTripTime * 1000) + " ms",
    },
    {
      name: "Available Outgoing Bitrate",
      value:
        (streamStats?.availableOutgoingBitrate / (1024 * 1024)).toFixed(2) +
        " mbps",
    },
    {
      name: "Packet Lost",
      value: streamStats?.video?.inbounds[0]?.totalPacketsLost,
    },
    {
      name: "Packet Received",
      value: streamStats?.video?.inbounds[0].totalPacketsReceived,
    },
    {
      name: "Candidate Type",
      value: streamStats?.candidateType,
    },
    {
      name: "Video Resolution",
      value:
        streamStats?.video?.inbounds[0]?.frameWidth +
        "x" +
        streamStats?.video?.inbounds[0]?.frameHeight,
    },
    {
      name: "FPS (Calculated)",
      value: streamStats.video.inbounds[0]?.framesPerSecond,
    },
    {
      name: "Video Bitrate",
      value:
        (streamStats?.video?.inbounds[0]?.bitrate / (1024 * 1024)).toFixed(2) +
        " mbps",
    },
    {
      name: "Audio Bitrate",
      value:
        (streamStats?.audio?.inbounds[0]?.bitrate / 1024).toFixed(2) + " kbps",
    },
    {
      name: "Video Total Sent",
      value:
        (
          streamStats?.video?.inbounds[0]?.totalBytesReceived /
          (1024 * 1024)
        ).toFixed(2) + " MB",
    },
    {
      name: "Audio Total Sent",
      value:
        (
          streamStats?.audio?.inbounds[0]?.totalBytesReceived /
          (1024 * 1024)
        ).toFixed(2) + " MB",
    },
    {
      name: "Codecs",
      value: streamStats?.video?.inbounds[0]?.mimeType,
    },
    {
      name: "Timestamp",
      value: new Date(
        streamStats?.audio?.inbounds[0]?.timestamp
      )?.toTimeString(),
    },
  ];

  return (
    showStreamStats &&
    streamStats && (
      <TableContainer
        style={{
          position: "absolute",
          top: "0px",
          left: "0px",
          width: "auto",
          backgroundColor: "#363a42a3",
        }}
        component={Paper}
      >
        <Table
          sx={{ minWidth: 700 }}
          style={{ backgroundColor: "#363a42a3" }}
          aria-label="customized table"
        >
          <TableBody>
            {filteredStreamStats.map((row) => (
              <StyledTableRow
                key={row.name}
                style={{ color: "white", border: "none" }}
              >
                <StyledTableCell
                  className="stream__stats_table"
                  style={{
                    color: "white",
                    border: "none",
                    minWidth: "fit-content",
                  }}
                  component="th"
                  scope="row"
                >
                  {row.name}
                </StyledTableCell>
                <StyledTableCell
                  align="left"
                  className="stream__stats_table"
                  style={{
                    color: "white",
                    border: "none",
                    minWidth: "fit-content",
                  }}
                >
                  {row.value}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    )
  );
}

import React, { useState } from "react";
import Dropdown from "../components/layout/dropdown/Dropdown";
import Form from "../components/layout/form/Form";
import KeyboardBtn from "../components/layout/controllers/KeyboardBtn";
import XboxController from "../components/layout/controllers/XboxController";

const controllerTypes = ["Keyboard", "PS-Controller", "Xbox-Controller"];

const allControllers = [
  {
    name: "Keyboard",
    main: <KeyboardBtn />,
  },
  {
    name: "Xbox-Controller",
    main: <XboxController />,
  },
  // {
  //   name: "PS-Controller",
  //   main: <PsJoystick />,
  // },
];

const JoystickConfig = () => {
  const [controllerType, setControllerType] = useState("");

  const getControllerType = (fromChild) => {
    setControllerType(fromChild);
  };

  return (
    <>
      {/* {console.log("Config Render")} */}
      <div className="components">
        <div
          className="joystick-div"
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            height: "100vh",
            padding: "10px",
            alignItems: "center",
          }}
        >
          <div
            className="dynamic-dropdown"
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "3rem",
              justifyContent: "center",
              position: "fixed",
              width: "25%",
              marginBottom: "50rem",
            }}
          >
            <Dropdown
              inputText="Controller Type"
              controllerTypes={controllerTypes}
              getControllerType={getControllerType}
            />
          </div>

          {controllerTypes.map((x, index) => {
            const controller = allControllers.find(
              (controller) => controller.name === x
            );
            return controllerType === x ? controller?.main : null;
          })}
        </div>
        <div className="form-div">
          <Form />
        </div>
      </div>
    </>
  );
};

export default JoystickConfig;

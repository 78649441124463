import { useEffect, useState } from "react";
import { map } from "lodash/fp";
import {
  ConfigInputs,
  Back,
  configInputs,
} from "../../joystick/ControlBtnStyles";
import { updateModifiedKey, updateModifiedAxis } from "../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import xbox from "../../../assets/images/x-box.png";
import Stick from "./gamepad-components/Stick";
import Shoulder from "./gamepad-components/Shoulder";
import Digital from "./gamepad-components/Digital";

const analogInputs = ["L", "R"];
const shoulderInputs = ["L2", "L1", "R2", "R1"];
const digitalInputs = [
  "dpadUp",
  "dpadDown",
  "dpadLeft",
  "dpadRight",
  "A",
  "B",
  "X",
  "Y",
  "start",
  "select",
  "home",
];

function Gamepad({ pressedColor, module }) {
  const { modifiedKey, modifiedAxis, controllerCommands } = useSelector(
    (state) => state.xboxControllerReducer
  );

  const [pressedKey, setPressedKey] = useState("");

  const [, setWaitingFor] = useState(null);

  const [allButtons, setAllButtons] = useState({
    buttons: {
      L1: 0,
      L2: 0,
      L3: 0,
      R1: 0,
      R2: 0,
      R3: 0,
      dpadUp: 0,
      dpadDown: 0,
      dpadLeft: 0,
      dpadRight: 0,
      A: 0,
      B: 0,
      X: 0,
      Y: 0,
      start: 0,
      select: 0,
      home: 0,
    },
    axis: {
      L: { x: 0, y: 0 },
      R: { x: 0, y: 0 },
    },
  });

  const dispatch = useDispatch();

  useEffect(() => {
    for (let i in allButtons.buttons) {
      if (allButtons.buttons[i] === 1) {
        //whenever the value of any button changes from 0 to 1, it will so some stuff
        setPressedKey(i);
        dispatch(updateModifiedKey(i));
      }
    }

    for (let i in allButtons.axis) {
      for (let j in allButtons.axis[i]) {
        if (allButtons.axis[i][j]) {
          setPressedKey(i);
          dispatch(updateModifiedAxis(allButtons.axis));
        }
      }
    }
  }, [allButtons, dispatch]);

  return (
    <>
      {/* {console.log("Gampad Render")} */}
      <div
        className={`relative main-config-joystick pointer-events-none h-full w-full flex flex-col justify-center items-center`}
        // style={{
        //   backgroundImage: `url(${xbox})`,
        // }}
      >
        <ConfigInputs>
          {pressedKey ? (
            <>
              {" "}
              <div
                className="pressed-key"
                style={{
                  border: "2px solid #7def7d",
                  borderRadius: "3px",
                  padding: "15px",
                  position: "fixed",
                  marginBottom: "36rem",
                }}
              >
                <h1 className="pressed-key-h1" style={{ fontSize: "23px" }}>
                  {pressedKey === "L" || pressedKey === "R"
                    ? `You Moved ${pressedKey}`
                    : `You Pressed ${pressedKey}`}
                </h1>
              </div>
            </>
          ) : null}
          <Back
            style={{
              // position: "relative",
              backgroundImage: `url(${xbox})`,
              backgroundRepeat: "no-repeat",
            }}
          >
            {map(
              (inputName) => (
                <Shoulder
                  key={inputName}
                  inputName={inputName}
                  module={module}
                  setAllButtons={setAllButtons}
                  allButtons={allButtons}
                  controllerCommands={controllerCommands}
                  modifiedAxis={modifiedAxis}
                  modifiedKey={modifiedKey}
                  dispatch={dispatch}
                  inputs={configInputs}
                />
              ),
              shoulderInputs
            )}
            {map(
              (inputName) => (
                <Stick
                  key={inputName}
                  inputName={inputName}
                  pressedColor={pressedColor}
                  module={module}
                  setWaitingFor={setWaitingFor}
                  setAllButtons={setAllButtons}
                  allButtons={allButtons}
                  controllerCommands={controllerCommands}
                  modifiedAxis={modifiedAxis}
                  modifiedKey={modifiedKey}
                  dispatch={dispatch}
                  inputs={configInputs}
                />
              ),
              analogInputs
            )}
            {map(
              (inputName) => (
                <Digital
                  key={inputName}
                  inputName={inputName}
                  pressedColor={pressedColor}
                  module={module}
                  setWaitingFor={setWaitingFor}
                  setAllButtons={setAllButtons}
                  allButtons={allButtons}
                  controllerCommands={controllerCommands}
                  modifiedAxis={modifiedAxis}
                  modifiedKey={modifiedKey}
                  dispatch={dispatch}
                  inputs={configInputs}
                />
              ),
              digitalInputs
            )}
          </Back>
        </ConfigInputs>
      </div>
    </>
  );
}

export default Gamepad;

import axios from "axios";

// const token='eyJhbGciOiJIUzI1NiJ9.eyJfaWQiOiI2MDNiNzBmNzc3MjA2MTQ1MWNmNzdhMzIiLCJkYXRlT2ZSZWdpc3RlcmF0aW9uIjoiMjAyMS0wMi0yOFQxMDozMToxOS4xMTNaIiwidXNlck5hbWUiOiJ0ZXN0LnVzZXIiLCJlbWFpbElkIjoidGVzdHVzZXJAdGVzdC5jb20iLCJmaXJzdE5hbWUiOiJ0ZXN0IiwibGFzdE5hbWUiOiJ1c2VyIiwibWlkZGxlTmFtZSI6IiIsInBhc3N3b3JkIjoidGVzdEAxMjMiLCJyb2xlIjoidGVzdCIsImltYWdlVXJsIjoidGVzdCIsImNyZWF0ZWRfYXQiOiIyMDIxLTAyLTI4VDEwOjMxOjE5LjExNFoiLCJ1cGRhdGVkQXQiOiIyMDIxLTAyLTI4VDEwOjMxOjE5LjExNFoiLCJfX3YiOjB9.N1fVw8a2_YA5LeomrbrM1GPDRc__qtMkOeHFWiCABmg'

const apiCall = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`,
});
export const login = (values) => {
  return axios
    .post(`${process.env.REACT_APP_BASE_URL}login/v1/login`, {
      emailId: values?.email,
      password: values?.password,
    })
    .then((res) => {
      return res;
    });
};

export const getFleetListUser = (values, token) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}fleet/v1/getFleetListByUserEmail`,
      {
        emailId: values,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};

export const getFleetListAdmin = (values, token) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}fleet/v1/getFleetListByAdminEmail`,
      {
        emailId: values,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};

export const cancelOrderAPI = (id, fleetid, token) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}order/v1/cancelOrder`,
      {
        orderId: id,
        fleetId: fleetid,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};
export const cancelActionAPI = (orderId, fleetid, actionId, token) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}order/v1/cancelAction`,
      {
        orderId: orderId,
        fleetId: fleetid,
        actionId: actionId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};
export const orderListFleetId = (values, page, size, token) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}order/v1/orderListByFleetIdPagination`,
      {
        fleetId: values,
        pageNo: page,
        size: size,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};
export const getorderstatus = (orderId, token) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}order/status`,
      {
        orderId: orderId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};
export const robotListfleetId = (values, token) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}robot/v1/getRobotStatusByFleetId`,
      {
        fleetId: values,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};

export const OpenCabinAPI = (orderid, fleetid, token) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}interrupt/v1/openDoorByOrderId`,
      {
        orderId: orderid,
        fleetId: fleetid,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};

export const MapDetails = (fleetid, token) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}map/v1/getMapDetailByfleetId`,
      {
        fleetId: fleetid,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};
export const getLocationList = (fleetid, token) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}location/v1/locationListByFleetId`,
      {
        fleetId: fleetid,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};

export const getMapLocationList = (fleetid, token) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}location/v1/locationListByFleetId`,
      {
        fleetId: fleetid,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};

export const getrobotStatus = (robotid, token) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}robot/v1/getRobotStatus`,
      {
        robotId: robotid,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};

export const sendHome = (robotId, token) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}robot/v1/sendRobotHome`,
      {
        robotId: robotId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};
export const moveToLocation = async (robotId, location, token) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}robot/moveToLocation`,
      {
        robotId: robotId,
        locationName : location?.locationName,
        latitude : location?.latitude ?? location?.lat,
        longitude : location?.longitude ?? location?.lon,
        headingAngle : location?.headingAngle
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};

export const getLocationListByType = (fleetId) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}v2/getLocationsByLocationType`,
      {
        fleetId: fleetId,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};

//  export const startViewer=(robotId, token)=>{
//     return axios.post(`${process.env.REACT_APP_APIOTTONOMY_URL}interrupt/v1/startVideoStream`, {
//         robotId:robotId,
//         stream:'ALL'
//     },{   headers:{
//               'Authorization':`Bearer ${token}`,
//               'Content-Type': 'application/json',
//           }
//       }).then((res)=>{
//         return res
//     })
//  }
//  export const stopViewer=(robotId, token)=>{
//     return axios.post(`${process.env.REACT_APP_APIOTTONOMY_URL}interrupt/v1/stopVideoStream`, {
//         robotId:robotId,
//         stream:'ALL'
//     },{   headers:{
//               'Authorization':`Bearer ${token}`,
//               'Content-Type': 'application/json',
//           }
//       }).then((res)=>{
//         return res
//     })
//  }
export const resetDiagnostics = (robotId, token) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}interrupt/v1/setRobotModeById`,
      {
        robotId: robotId,
        robotMode: "RESET_DIAGNOSTICS",
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};
export const restartVideo = (robotId, token) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}interrupt/v1/setRobotModeById`,
      {
        robotId: robotId,
        robotMode: "RESTART_MULTI_STREAM_VIDEO",
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};
export const stopVideo = (robotId, token) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}interrupt/v1/setRobotModeById`,
      {
        robotId: robotId,
        robotMode: "STOP_MULTI_STREAM_VIDEO",
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
}

export const DisengageMotors = (robotId, token, State) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}interrupt/v1/setRobotModeById`,
      {
        robotId: robotId,
        robotMode: State,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};
export const openSSH = (robotId, token, sessionState) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}interrupt/v1/setSshSessionbyRobotId`,
      {
        robotId: robotId,
        sessionStatus: sessionState,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};

export const Autorunplay = (robotId, token) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}interrupt/v1/sendRunCommand`,
      {
        robotId: robotId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};
export const RobotModeById = (robotId, token, runningState) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}interrupt/v1/setRobotModeById`,
      {
        robotId: robotId,
        robotMode: runningState,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};

export const openDoor = (robotId, token, cabin) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}interrupt/v1/openDoorByRobotId`,
      {
        robotId: robotId,
        compartment: cabin,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};
export const openDoorTwo = (robotId, token) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}interrupt/v1/openDoorByRobotId`,
      {
        robotId: robotId,
        compartment: "CABIN2",
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};
export const closeDoor = (robotId, token, cabin) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}interrupt/v1/closeDoorByRobotId`,
      {
        robotId: robotId,
        compartment: cabin,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};
export const closeDoorTwo = (robotId, token) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}interrupt/v1/closeDoorByRobotId`,
      {
        robotId: robotId,
        compartment: "CABIN2",
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};
export const RobotTestingMode = (robotId, token) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}robot/v1/robotTestingMode`,
      {
        robotId: robotId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};

export const changeRobotWanderStatus = (robotId, token, wandermode) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}robot/v1/changeRobotWanderStatus`,
      {
        robotId: robotId,
        wanderStatus: "true",
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};

export const changeRingLightStatus = (robotId, token, ringlight) => {
  if (ringlight) {
    return axios
      .post(
        `${process.env.REACT_APP_BASE_URL}interrupt/v1/sendHeadlightCommand`,
        {
          robotId: robotId,
          interruptAction: "DISABLE_RINGLIGHT",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        return res;
      });
  } else {
    return axios
      .post(
        `${process.env.REACT_APP_BASE_URL}interrupt/v1/sendHeadlightCommand`,
        {
          robotId: robotId,
          interruptAction: "ENABLE_RINGLIGHT",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        return res;
      });
  }
};
export const changeHeadLightStatus = (robotId, token, headlight) => {
  if (headlight) {
    return axios
      .post(
        `${process.env.REACT_APP_BASE_URL}interrupt/v1/sendHeadlightCommand`,
        {
          robotId: robotId,
          interruptAction: "DISABLE_HEADLIGHT",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        return res;
      });
  } else {
    return axios
      .post(
        `${process.env.REACT_APP_BASE_URL}interrupt/v1/sendHeadlightCommand`,
        {
          robotId: robotId,
          interruptAction: "ENABLE_HEADLIGHT",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        return res;
      });
  }
};
export const EmergencyStop = (robotId, token) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}interrupt/v1/emergencyStop`,
      {
        robotId: robotId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};
export const ReleaseEmergencyStop = (robotId, token) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}interrupt/v1/releaseEmergencyStop`,
      {
        robotId: robotId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};

export const playrobot = (robotId, token) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}interrupt/v1/sendRunCommand`,
      {
        robotId: robotId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};
export const pauserobot = (robotId, token) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}interrupt/v1/sendPauseCommand`,
      {
        robotId: robotId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};

export const robotactionapi = (robotId, token) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}robot/v1/getRobotCommandListByRobotId`,
      {
        robotId: robotId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};

export const updateRobotAction = (
  robotId,
  token,
  status,
  actionId,
  orderId
) => {
  if(parseInt(status) === 3) {
    return axios
      .post(
        `${process.env.REACT_APP_BASE_URL}order/v1/completeAction`,
        {
          robotId: robotId,
          orderId: orderId,
          actionId: actionId,
          actionStatus: status,
          cancelCommand: true
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        return res;
      });
  } else {
    return axios
      .post(
        `${process.env.REACT_APP_BASE_URL}order/v1/completeAction`,
        {
          robotId: robotId,
          orderId: orderId,
          actionId: actionId,
          actionStatus: status,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        return res;
      });
  }
};
// export const CancelOrder=(token,fleetId )=>{
//     return axios.post(`${process.env.REACT_APP_BASE_URL}order/v1/cancelOrder`, {
//         orderId: "1fxl2b0hkwdksfd1",
//         fleetId:fleetId
//     },{   headers:{
//               'Authorization':`Bearer ${token}`,
//               'Content-Type': 'application/json',
//           }
//       }).then((res)=>{
//         return res
//     })
// }
export const teleopComplete = (token, fleetId) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}order/v1/completeAction`,
      {
        robotId: "ob01_2022_05_01",
        orderId: "test",
        actionId: "1gtql0rl9fltisk",
        actionStatus: "1",
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};

export const relocalization = (token, robotId, metricX, metricY, yaw) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}interrupt/v1/sendrelocalize`,
      {
        robotId: robotId,
        interruptAction: "RELOCALIZE",
        metricPositionX: metricX,
        metricPositionY: metricY,
        metricPositionYaw: yaw,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};

export const handleteleopAck = (robotId, token) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}robot/v1/updateTeleopCompleted`,
      {
        robotId: robotId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};

export const handleTeleopComplete = (robotId, token) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}interrupt/v1/teleopCompleted`,
      {
        robotId: robotId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};

export const handleTeleopAckInterrupt = (robotId, token) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}interrupt/v1/teleopACK`,
      {
        robotId: robotId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};

export const handleResumeAutoInterrupt = (robotId, token) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}interrupt/v1/sendHeadlightCommand`,
      {
        robotId: robotId,
        interruptAction: "RESUME_AUTO",
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};

export const handleClearCommandsInterrupt = (robotId, token) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}interrupt/v1/sendHeadlightCommand`,
      {
        robotId: robotId,
        interruptAction: "CLEAR_COMMANDS",
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};

export const robotListByFleetId = (fleetId, token) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}robot/v1/robotListByFleetId`,
      {
        fleetId: fleetId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};

export default apiCall;

export const getrobotpath = (robotId, token) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}robot/path`,
      {
        robotId: robotId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};

export const getrobotpathMain = (robotId, token) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}robot/v2/getRobotPath`,
      {
        robotId: robotId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};

export const getgeofenceZone = (fleetId, token) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}robot/v2/getFleetGeofence`,
      {
        fleetId: fleetId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};

export const getfleetzones = (fleetId, token) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}robot/v2/getFleetZones`,
      {
        fleetId: fleetId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};

export const getRelocalizationPoints = (fleetId, token) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}v2/getLocationsByLocationType`,
      {
        fleetId: fleetId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};
export const getnogozones = (fleetId, token) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}robot/v2/getFleetNogoZones`,
      {
        fleetId: fleetId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};

import { Route, Routes } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import SelectFleet from "./pages/SelectFleet";
import IndividualStream from "./pages/IndividualStream";
import JoystickConfig from "./pages/JoystickConfig";
import "./App.css";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path={"/:encrypt"} element={<Login />} />
        <Route path={"/dashboard/:id"} element={<Dashboard />} />
        <Route path={"/selectfleet"} element={<SelectFleet />} />
        <Route path={"/stream/:id"} element={<IndividualStream />} />
        <Route path={"/controller/config"} element={<JoystickConfig />} />
      </Routes>
    </div>
  );
}

export default App;

import { FaArrowUpLong } from "react-icons/fa6";
import "./CustomControlBtn.css";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faArrowUpLong } from "@fortawesome/free-solid-svg-icons";

const CustomControlBtn = ({ controlPositions }) => {
  return (
    <div>
      <div className="btn-bkg">
        <div className="btn-star"></div>
        <div className="btn-up">
          {controlPositions === "L" ? "W" : <FaArrowUpLong />}
        </div>

        <div className="btn-down">
          {controlPositions === "L" ? (
            "S"
          ) : (
            <FaArrowUpLong style={{ transform: "rotate(-180deg)" }} />
          )}
        </div>
        <div className="btn-right">
          {controlPositions === "L" ? (
            "D"
          ) : (
            <FaArrowUpLong style={{ transform: "rotate(90deg)" }} />
          )}
        </div>
        <div className="btn-left">
          {controlPositions === "L" ? (
            "A"
          ) : (
            <FaArrowUpLong style={{ transform: "rotate(-90deg)" }} />
          )}
        </div>
      </div>
    </div>
  );
};

export default CustomControlBtn;

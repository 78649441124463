import { useEffect, useCallback, useState, memo } from "react";
import { createJoymap, createQueryModule } from "joymap";
import Gamepad from "./Gamepad";

const joymap = createJoymap();
const module = createQueryModule();
joymap.addModule(module);

const XboxController = () => {
  const [, updateState] = useState({});
  const forceUpdate = useCallback(() => updateState({}), []);

  useEffect(() => {
    joymap.setOnPoll(forceUpdate);
    joymap.start();

    return () => {
      joymap.stop();
    };
  }, []);

  return <Gamepad module={module} pressedColor={`rgb(41 255 2)`}></Gamepad>;
};

export default memo(XboxController);

import { memo, useEffect, useState } from "react";
import { FormSwitch } from "./FormSwitch";
import { FaRegQuestionCircle } from "react-icons/fa";
import { Button } from "@mui/material";
import { sendCommandData } from "../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import Dropdown from "../dropdown/Dropdown";
import TextField from "@mui/material/TextField";
import CustomControlBtn from "../../joystick/CustomControlBtn";
import axios from "axios";
import "./Form.css";

//for setting up Command using a single key
// function ChildModal({ keyPressed, getValueFromChildModal }) {
//   const [pressedKey, setPressedKey] = useState("");

//   const [open, setOpen] = useState(false);
//   const handleOpen = () => {
//     setOpen(true);
//   };
//   const handleClose = () => {
//     setOpen(false);
//   };

//   const handleChange = () => {
//     setPressedKey(keyPressed); //not setting the xobx button key
//     getValueFromChildModal(pressedKey);
//   };

//   return (
//     <>
//       <Button onClick={handleOpen}>
//         <FontAwesomeIcon
//           icon={faPenToSquare}
//           style={{ color: "#04ff00", fontSize: "28px" }}
//         />
//       </Button>
//       <Modal
//         open={open}
//         onClose={handleClose}
//         aria-labelledby="child-modal-title"
//         aria-describedby="child-modal-description"
//       >
//         <Box
//           sx={{
//             ...style,
//             width: 200,
//             position: "absolute",
//             top: "50%",
//             left: "50%",
//             transform: "translate(-50%, -50%)",
//             gap: "1rem",
//           }}
//         >
//           <h2 id="child-modal-title">Press a key</h2>
//           <input
//             type="text"
//             name="fname"
//             value={pressedKey}
//             style={{ ...inputStyle }}
//             onChange={handleChange}
//           />
//           <Button onClick={handleClose}>Close</Button>
//         </Box>
//       </Modal>
//     </>
//   );
// }

const Form = () => {
  const [time, setTime] = useState(new Date());

  useEffect(() => {
    let conTime = setInterval(() => setTime(new Date()), 1000);

    return () => clearInterval(conTime);
  }, []);

  //Fetching data from Redux
  const { modifiedKey, modifiedAxis, controllerCommands } = useSelector(
    (state) => state.xboxControllerReducer
  );

  //Dispatching API data to globally in redux
  const dispatch = useDispatch();

  //Main configForm that is being used to Post,Put or Patch data - (This is also default values)
  const [configForm, setConfigForm] = useState({
    "Deadband X Max": 0.2,
    "Deadband Y Max": 0.2,
    "Deadband X Min": -0.2,
    "Deadband Y Min": -0.2,
    "Max V X": 0.5,
    "Max V Y": 0.5,
    "Min V X": -0.5,
    "Min V Y": -0.5,
    Positions: "",
  });

  const handleChange = (event) => {
    setConfigForm({ ...configForm, [event.target.name]: event.target.value });
  };

  //Child Modal
  const [childData, setChildData] = useState("");

  const getValueFromChildModal = (data) => {
    setChildData(data);
  };

  const positions = ["L", "R"];

  //Update the form data to MongoDB
  const handleSave = async (e) => {
    e.preventDefault();
    try {
      const result = await axios.patch(
        `http://localhost:5460/xbox/updateValues/${configForm["Positions"]}`,
        configForm
      );

      dispatch(sendCommandData(result.data.values));
    } catch (error) {
      console.log(error);
    }
  };

  //Lifting up state
  const getControllerType = (position) => {
    setConfigForm((prevState) => ({
      ...prevState,
      Positions: position,
    }));
  };

  return (
    <>
      {/* {console.log("render")} */}
      <form>
        <div className="main-form">
          <div className="header">
            <h2 className="main-heading">Joystick configuration</h2>
          </div>

          <div className="position-section">
            <div className="single-div">
              <p>POSITION</p>
              <div className="right-items">
                <Dropdown
                  inputText="Positions"
                  positions={positions}
                  getControllerType={getControllerType}
                />
                <FormSwitch />
              </div>
            </div>
            <div className="single-div">
              <p>HORIZONTAL AXIS</p>
              <div className="right-items">
                <TextField
                  className="MuiTextFieldNumer"
                  value={configForm["Deadband X Max"]}
                  onChange={handleChange}
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  id="outlined-basic"
                  label="Deadband X Max"
                  name="Deadband X Max"
                  variant="outlined"
                  color="primary"
                  sx={{ color: "white", background: "#2b2a44", width: "100%" }}
                />
                <TextField
                  className="MuiTextFieldNumer"
                  value={configForm["Deadband X Min"]}
                  onChange={handleChange}
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  id="outlined-basic"
                  label="Deadband X Min"
                  name="Deadband X Min"
                  variant="outlined"
                  color="primary"
                  sx={{ color: "white", background: "#2b2a44", width: "100%" }}
                />
                <TextField
                  className="MuiTextFieldNumer"
                  value={configForm["Max V X"]}
                  onChange={handleChange}
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  id="outlined-basic"
                  label="Max V X"
                  name="Max V X"
                  variant="outlined"
                  color="primary"
                  sx={{ color: "white", background: "#2b2a44", width: "100%" }}
                />
                <TextField
                  className="MuiTextFieldNumer"
                  value={configForm["Min V X"]}
                  onChange={handleChange}
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  id="outlined-basic"
                  label="Min V X"
                  name="Min V X"
                  variant="outlined"
                  color="primary"
                  sx={{ color: "white", background: "#2b2a44", width: "100%" }}
                />
              </div>
            </div>
            <div className="single-div">
              <p>VERTICAL AXIS</p>
              <div className="right-items">
                <TextField
                  className="MuiTextFieldNumer"
                  value={configForm["Deadband Y Max"]}
                  onChange={handleChange}
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  id="outlined-basic"
                  label="Deadband Y Max"
                  name="Deadband Y Max"
                  variant="outlined"
                  color="primary"
                  sx={{ color: "white", background: "#2b2a44", width: "100%" }}
                />
                <TextField
                  className="MuiTextFieldNumer"
                  value={configForm["Deadband Y Min"]}
                  onChange={handleChange}
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  id="outlined-basic"
                  label="Deadband Y Min"
                  name="Deadband Y Min"
                  variant="outlined"
                  color="primary"
                  sx={{ color: "white", background: "#2b2a44", width: "100%" }}
                />
                <TextField
                  className="MuiTextFieldNumer"
                  value={configForm["Max V Y"]}
                  onChange={handleChange}
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  id="outlined-basic"
                  label="Max V Y"
                  name="Max V Y"
                  variant="outlined"
                  color="primary"
                  sx={{ color: "white", background: "#2b2a44", width: "100%" }}
                />
                <TextField
                  className="MuiTextFieldNumer"
                  value={configForm["Min V Y"]}
                  onChange={handleChange}
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  id="outlined-basic"
                  label="Min V Y"
                  name="Min V Y"
                  variant="outlined"
                  color="primary"
                  sx={{ color: "white", background: "#2b2a44", width: "100%" }}
                />
              </div>
            </div>

            <div className="controlls-submit-btn-div">
              <Button
                variant="contained"
                className="controlls-submit-btn"
                onClick={handleSave}
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      </form>

      <div className="preview">
        <div className="header-1">
          <h3 className="main-heading">Preview</h3>
        </div>

        <div className="preview-section">
          <div className="left-side">
            <div className="inner-left-side">
              <div className="left-joystick">
                <p className="inner-main-headings">LEFT</p>
                <div className="main-item-div">
                  <div className="preview-items-left">
                    <div className="preview-inner-item">
                      <h4 className="inner-main-subheadings">Mouse</h4>
                      <p className="inner-p">false</p>
                    </div>
                    <div className="preview-inner-item">
                      <h4 className="inner-main-subheadings">Gamepad</h4>
                      <p className="inner-p">false</p>
                    </div>
                  </div>
                  <div className="preview-items-rigth">
                    <CustomControlBtn controlPositions={"L"} />
                  </div>
                </div>
              </div>
            </div>
            <div className="inner-right-side">
              <div className="left-joystick">
                <p className="inner-main-headings">RIGHT</p>
                <div className="main-item-div">
                  <div className="preview-items-left">
                    <div className="preview-inner-item">
                      <h4 className="inner-main-subheadings">Mouse</h4>
                      <p className="inner-p">false</p>
                    </div>
                    <div className="preview-inner-item">
                      <h4 className="inner-main-subheadings">Gamepad</h4>
                      <p className="inner-p">false</p>
                    </div>
                  </div>
                  <div className="preview-items-rigth">
                    <CustomControlBtn controlPositions={"R"} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="right-side">
            <div className="right-side-inner-section">
              <p className="inner-main-headings">OUTPUT</p>
              <div className="right-side-items">
                <div className="linear">
                  <h4 className="inner-main-subheadings">Linear</h4>
                  <div className="linear-items">
                    <div className="x">
                      <p className="inner-p">X:</p>
                      <p className="inner-p">
                        {modifiedAxis && modifiedAxis.L.x}
                      </p>
                    </div>
                    <div className="y">
                      <p className="inner-p">Y:</p>
                      <p className="inner-p">
                        {modifiedAxis && modifiedAxis.L.y}
                      </p>
                    </div>
                    {/* <div className="z">
                      <p className="inner-p">Z:</p>
                      <p className="inner-p">0.220</p>
                    </div> */}
                  </div>
                </div>
                <div className="angular">
                  <h4 className="inner-main-subheadings">Angular</h4>
                  <div className="linear-items">
                    <div className="x">
                      <p className="inner-p">X:</p>
                      <p className="inner-p">
                        {modifiedAxis && modifiedAxis.R.x}
                      </p>
                    </div>
                    <div className="y">
                      <p className="inner-p">Y:</p>
                      <p className="inner-p">
                        {modifiedAxis && modifiedAxis.R.y}
                      </p>
                    </div>
                    {/* <div className="z">
                      <p className="inner-p">Z:</p>
                      <p className="inner-p">0.220</p>
                    </div> */}
                  </div>
                </div>
                <div className="time">
                  <h4 className="inner-main-subheadings">Time</h4>
                  <p className="inner-p">{time.toLocaleTimeString()}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bottom-options">
        <div className="single-div">
          <div
            className="left-side-text"
            style={{ display: "flex", alignItems: "center", gap: "1rem" }}
          >
            <p>Disable Gamepad Trigger Axis Mapping</p>
            <FaRegQuestionCircle />
          </div>

          <div className="right-items-bottom">
            <p>DISABLED</p>

            <FormSwitch />
          </div>
        </div>
        <div className="single-div">
          <div
            className="left-side-text"
            style={{ display: "flex", alignItems: "center", gap: "1rem" }}
          >
            <p>Disable Gamepad Input and Keyboard Input</p>
            <FaRegQuestionCircle />
          </div>
          <div className="right-items-bottom">
            <p>DISABLED</p>
            <FormSwitch />
          </div>
        </div>
      </div>
      {/* <div className="single-div">
        <p>COMMANDS</p>
        <div className="right-items" style={{ flexDirection: "column" }}>
          <Box sx={{ ...style }}>
            <form style={{ width: "100%" }}>
              <div
                className="modal-form"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {comands &&
                  comands.map((comand, index) => {
                    return (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          width: "100%",
                          alignItems: "center",
                        }}
                      >
                        <label htmlFor="fname">Comand {index + 1} :</label>
                        <p>{childData}</p>
                        <ChildModal
                          keyPressed={modifiedKey}
                          getValueFromChildModal={getValueFromChildModal}
                        />
                      </div>
                    );
                  })}
              </div>
            </form>
          </Box>
        </div>
      </div> */}
    </>
  );
};

export default memo(Form);

import { useEffect } from "react";

function Digital({
  inputName,
  pressedColor,
  module,
  setWaitingFor,
  setAllButtons,
  allButtons,
  controllerCommands,
  modifiedAxis,
  modifiedKey,
  dispatch,
  inputs,
}) {
  const { pressed } = module.getButton(inputName);
  const DigitalComponent = inputs[inputName];

  useEffect(() => {
    setAllButtons({
      ...allButtons,
      buttons: { ...allButtons.buttons, [inputName]: +pressed },
    });
  }, [pressed]);

  return (
    <>
      {" "}
      {/* {console.log("Shoulder Render")} */}
      <DigitalComponent
        style={{ backgroundColor: pressed ? pressedColor : "" }}
        onClick={() => {
          if (module.isConnected()) {
            module.buttonBindOnPress(inputName, () => setWaitingFor(null));
            setWaitingFor(inputName);
          }
        }}
      />
    </>
  );
}

export default Digital;

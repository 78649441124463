import { useState, useEffect, useCallback, useRef } from "react";
import { sendCommandData, updateModifiedAxis } from "../../../../redux/actions";
import axios from "axios";
import Draggable from "react-draggable";

function Stick({
  inputName,
  pressedColor,
  module,
  setWaitingFor,
  setAllButtons,
  allButtons,
  controllerCommands,
  modifiedAxis,
  modifiedKey,
  dispatch,
  inputs,
}) {
  let decimalFixed = 2;
  let intervalFreq = 10;
  // useEffect(() => {
  //   console.log("Form redux",controllerCommands)
  // }, [controllerCommands])  //for testing purpose only

  const [x, y] = module.getStick(inputName).value;
  const { pressed } = module.getButton(`${inputName}3`);
  const StickComponent = inputs[inputName];

  const [arrState, setArrState] = useState(null);

  const [values, setValues] = useState({ x: 0, y: 0 });
  const [position, setPosition] = useState(null);

  const [mouseDrag, setMouseDrag] = useState(false); //need to work

  const draggerRef = useRef(null);

  // Fires when the user stops dragging the element
  const resetDrag = useCallback(() => {
    setPosition({ x: 0, y: 0 });
    draggerRef.current?.setState({ x: 0, y: 0 }); // This is what resets it!
  }, [setPosition, draggerRef]);

  const handleDrag = (e, data) => {
    e.preventDefault();
    setMouseDrag(true);
    setValues({ x: data.x, y: data.y });
    console.log(values);
  };

  const handleXboxChange = (e) => {
    e.preventDefault();
    setMouseDrag(false);
  };
  // --------------------------------------------------------------------

  useEffect(() => {
    const getValues = async () => {
      try {
        const result = await axios.get(`http://localhost:5460/xbox/getValues`);
        dispatch(sendCommandData(result.data.values));
        setArrState(result.data.values);
      } catch (error) {
        console.log(error);
      }
    };

    return () => getValues();
  }, []);

  useEffect(() => {
    if (controllerCommands) {
      setArrState(controllerCommands[0]);
    }
    //Dead-Band Code
    let v_y = 0.0;
    let v_x = 0.0;
    if (arrState) {
      if (inputName === "R" && arrState[1]["Positions"] === "R") {
        if (x >= Number(arrState[1]["Deadband X Max"])) {
          v_x =
            Number(arrState[1]["Max V X"]) *
            (1.0 / (1.0 - Number(arrState[1]["Deadband X Max"]))) *
            (x - Number(arrState[1]["Deadband X Max"]));
        } else if (x <= -Math.abs(arrState[1]["Deadband X Min"])) {
          v_x =
            Number(arrState[1]["Max V X"]) *
            (1.0 / (1.0 + -Math.abs(arrState[1]["Deadband X Min"]))) *
            (x - -Math.abs(arrState[1]["Deadband X Min"]));
        } else {
          v_x = 0.0;
        }

        if (-y >= Number(arrState[1]["Deadband Y Max"])) {
          v_y =
            Number(arrState[1]["Max V Y"]) *
            (1.0 / (1.0 - Number(arrState[1]["Deadband Y Max"]))) *
            (-y - Number(arrState[1]["Deadband Y Max"]));
        } else if (-y <= -Math.abs(arrState[1]["Deadband Y Min"])) {
          v_y =
            Number(arrState[1]["Max V Y"]) *
            (1.0 / (1.0 + -Math.abs(arrState[1]["Deadband Y Min"]))) *
            (-y - -Math.abs(arrState[1]["Deadband Y Min"]));
        } else {
          v_y = 0.0;
        }
      } else if (inputName === "L" && arrState[0]["Positions"] === "L") {
        if (x >= Number(arrState[0]["Deadband X Max"])) {
          v_x =
            Number(arrState[0]["Max V X"]) *
            (1.0 / (1.0 - Number(arrState[0]["Deadband X Max"]))) *
            (x - Number(arrState[0]["Deadband X Max"]));
        } else if (x <= -Math.abs(arrState[0]["Deadband X Min"])) {
          v_x =
            Number(arrState[0]["Max V X"]) *
            (1.0 / (1.0 + -Math.abs(arrState[0]["Deadband X Min"]))) *
            (x - -Math.abs(arrState[0]["Deadband X Min"]));
        } else {
          v_x = 0.0;
        }

        if (-y >= Number(arrState[0]["Deadband Y Max"])) {
          v_y =
            Number(arrState[0]["Max V Y"]) *
            (1.0 / (1.0 - Number(arrState[0]["Deadband Y Max"]))) *
            (-y - Number(arrState[0]["Deadband Y Max"]));
        } else if (-y <= -Math.abs(arrState[0]["Deadband Y Min"])) {
          v_y =
            Number(arrState[0]["Max V Y"]) *
            (1.0 / (1.0 + -Math.abs(arrState[0]["Deadband Y Min"]))) *
            (-y - -Math.abs(arrState[0]["Deadband Y Min"]));
        } else {
          v_y = 0.0;
        }
      }
    }

    setAllButtons({
      ...allButtons,
      axis: {
        ...allButtons.axis,
        [inputName]: {
          ...allButtons.axis[inputName],
          x: Number(v_x.toFixed(decimalFixed)), //Reversed x and y values to negetive for both L and R after 10/06/2023
          y: Number(v_y.toFixed(decimalFixed)),
        },
      },
    });

    dispatch(updateModifiedAxis(allButtons.axis));

    console.log("X::::", x, "Y::::", y);
    console.log(allButtons.axis.L);
  }, [controllerCommands, inputName, x, y]);

  useEffect(() => {
    setAllButtons({
      ...allButtons,
      buttons: { ...allButtons.buttons, [`${inputName}3`]: +pressed },
    });
  }, [pressed]);

  return (
    <>
      {mouseDrag ? (
        <Draggable
          ref={draggerRef}
          onDrag={handleDrag}
          onStop={resetDrag}
          position={position}
        >
          <StickComponent
            onClick={() => {
              if (module.isConnected()) {
                // module.stickBindOnPress(inputName, () => setWaitingFor(null));
                // setWaitingFor(inputName);
              }
            }}
            style={{
              transform: `translate(${x * 35}px, ${y * 35}px)`,
              backgroundColor: pressed ? pressedColor : "",
            }}
          />
        </Draggable>
      ) : (
        <StickComponent
          onChange={handleXboxChange}
          onClick={() => {
            if (module.isConnected()) {
              // module.stickBindOnPress(inputName, () => setWaitingFor(null));
              // setWaitingFor(inputName);
            }
          }}
          style={{
            transform: `translate(${x * 35}px, ${y * 35}px)`,
            backgroundColor: pressed ? pressedColor : "",
          }}
        />
      )}
    </>
  );
}

export default Stick;

import { useParams } from "react-router-dom";
import { useCallback, useEffect, useRef, useState } from "react";
import { MainStream } from "../components/stream/main";
import { createJoymap, createQueryModule } from "joymap";
import ControlBtnImage from "../components/joystick/ControlBtnImage";
import Stream from "../components/stream/Stream";

const joymap = createJoymap();
const module = createQueryModule();
joymap.addModule(module);
// console.log(module);

const IndividualStream = () => {
  const [streamLive, setStreamLive] = useState(false);
  const [streamView, setStreamView] = useState();
  const [rtcPeerConnection, setRtcPeerConnection] = useState();
  const [, setViewCount] = useState({});
  const [streamStats, setStreamStats] = useState();
  const [showStreamStats, setShowStreamStats] = useState(false);
  const [, setIsPictureInPicture] = useState(false);
  const [isMetadataLoaded, setIsMetadataLoaded] = useState(false);
  const [conlock, setConLock] = useState(true);
  const [, updatedState] = useState({});
  const [state, setState] = useState({
    testingmode: false,
    wandermode: false,
    ringlight: false,
    headlight: false,
    robotpathcheck: false,
    googlemap: false,
    streamclient: false,
    restartstream: false,
  });

  const streamRefLocal = localStorage.getItem("streamRef");
  const streamRef = useRef(streamRefLocal ? streamRefLocal : null);

  const params = useParams();
  let robotId = params.id;

  const forceUpdate = useCallback(() => updatedState({}), []);

  const openExtendedWindow = async () => {
    const windowDetails = await window.getScreenDetails();

    if (window.screen.isExtended) {
      windowDetails.screens.forEach((screen) => {
        if (!screen.isPrimary) {
          window.open(
            `/stream/${robotId}`,
            "",
            `width=${screen.availWidth},height=${screen.height},top=${screen.top},left=${screen.left},fullscreen=yes`
          );
        } else {
          localStorage.setItem("streamRef", "none");
        }
      });
    }
  };

  const handleExtendScreen = async () => {
    await openExtendedWindow();
  };

  const handleReduceScreen = () => {
    localStorage.setItem("streamRef", "block");
    window.close();
  };

  const togglePictureInPicture = () => {
    const videoElement = streamRef.current;

    if (!isMetadataLoaded) {
      console.log("Video metadata is not loaded yet.");
      return;
    }

    if (document.pictureInPictureElement === videoElement) {
      document
        .exitPictureInPicture()
        .then(() => {
          setIsPictureInPicture(false);
          streamRef.current.style.display = "block";
        })
        .catch((error) => {
          console.error("Failed to exit Picture-in-Picture mode:", error);
        });
    } else {
      videoElement
        .requestPictureInPicture()
        .then(() => {
          setIsPictureInPicture(true);
          // streamRef.current.style.display = "none";
        })
        .catch((error) => {
          console.error("Failed to enter Picture-in-Picture mode:", error);
        });
    }
  };

  const handleMetadataLoaded = () => {
    setIsMetadataLoaded(true);
  };

  // useEffect(() => {
  //   if (!streamRefLocal) {
  //     localStorage.setItem("streamRef", "block");
  //   }
  // });

  useEffect(() => {
    MainStream(
      streamLive,
      setStreamLive,
      setViewCount,
      setStreamStats,
      robotId,
      streamView,
      setStreamView,
      rtcPeerConnection,
      setRtcPeerConnection
    );

    joymap.setOnPoll(forceUpdate);
    joymap.start();

    return () => {
      joymap.stop();
    };
  }, [robotId]);

  // window.addEventListener("load", (e) => {
  //   localStorage.setItem("streamRef", "none");
  // });

  window.addEventListener("beforeunload", (ev) => {
    // streamRef.current.display = "block";
    localStorage.setItem("streamRef", "block");
  });

  if (performance.navigation.type === performance.navigation.TYPE_RELOAD) {
    console.info("This page is reloaded");
    localStorage.setItem("streamRef", "none");
  } else {
    console.info("This page is not reloaded");
  }

  return (
    <>
      <section className="extended__screen_container">
        <Stream
          streamRef={streamRef}
          streamLive={streamLive}
          handleMetadataLoaded={handleMetadataLoaded}
          streamStats={streamStats}
          setStreamStats={setStreamStats}
          showStreamStats={showStreamStats}
          streamView={streamView}
          setShowStreamStats={setShowStreamStats}
          isMetadataLoaded={isMetadataLoaded}
          togglePictureInPicture={togglePictureInPicture}
          handleExtendScreen={handleExtendScreen}
          handleReduceScreen={handleReduceScreen}
        />
      </section>
      {streamLive && (
        <div
          style={{
            position: "absolute",
            zIndex: "100",
            bottom: "0rem",
            right: "0rem",
            // display: unlock ? "" : "none",
            pointerEvents: "none",
          }}
        >
          <ControlBtnImage
            module={module}
            pressedColor={`rgb(41 255 2)`}
            conlock={conlock}
            robotId={robotId}
            state={state}
            setState={setState}
            latency={
              streamStats?.hasOwnProperty("currentRoundTripTime")
                ? Math.ceil(streamStats.currentRoundTripTime * 1000)
                : 1000
            }
          ></ControlBtnImage>
        </div>
      )}
    </>
  );
};

export default IndividualStream;

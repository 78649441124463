import { configureStore, combineReducers } from "@reduxjs/toolkit";
import {
  robotdatareducer,
  robotlistReducer,
  fleetlatitudeReducer,
  fleetlongitudeReducer,
  fleetIdreducer,
  robotMessageReducer,
  robotidReducer,
  sshendpointReducer,
  robotDetailsReducer,
  orderStatusReducer,
  robotDirectionsReducer,
  userAuthReducer,
  xboxControllerReducer,
} from "./reducers";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";

const persistConfig = {
  key: "root",
  storage,
};

const initialState = {};

const reducers = combineReducers({
  fleetIdreducer,
  robotdatareducer,
  robotMessageReducer,
  robotidReducer,
  robotlistReducer,
  sshendpointReducer,
  fleetlatitudeReducer,
  fleetlongitudeReducer,
  robotDetailsReducer,
  orderStatusReducer,
  robotDirectionsReducer,
  userAuthReducer,
  xboxControllerReducer,
});

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
  preloadedState: initialState,
});

export const persistor = persistStore(store);

import { useEffect } from "react";

function Shoulder({
  inputName,
  module,
  allButtons,
  setAllButtons,
  inputs,
  controllerCommands,
  modifiedAxis,
  modifiedKey,
  dispatch,
}) {
  const { value } = module.getButton(inputName);
  const ShoulderComponent = inputs[inputName];

  useEffect(() => {
    setAllButtons({
      ...allButtons,
      buttons: { ...allButtons.buttons, [inputName]: value },
    });
  }, [value]);

  return (
    <div
      style={{
        position: "fixed",
        transform: `translateY(${value * 10}px)`,
      }}
    >
      {/* {console.log("Shoulder Render")} */}
      <ShoulderComponent />
    </div>
  );
}

export default Shoulder;
